import { z } from "zod";
import { extendZodWithOpenApi } from "@asteasolutions/zod-to-openapi";
import React from "react";
import ReactDOM from "react-dom/client";
import "@oneloop-hq/frontend-react-sdk/dist/assets/app.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Toaster } from "@/components/ui/sonner";
import { RecoilRoot } from "recoil";
import RecoilNexus from "recoil-nexus";

// Import the OpenAPI registry and extend Zod with OpenAPI before importing the schemas
extendZodWithOpenApi(z);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <RecoilNexus />
      <App />
      <Toaster />
    </RecoilRoot>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
