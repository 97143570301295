import { ApiCalls } from "@/lib/ApiCall";
import GlobalAtoms from "@/lib/GlobalStore";
import { Button, Snippet } from "@nextui-org/react";
import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";

const TestAPI = () => {
  const [signalReceived, setSignalReceived] = useState(false);
  const [currenWorkspace, setCurrentWorkspace] = useRecoilState(
    GlobalAtoms.SelectedWorkspaceAtom
  );
  const [onboardingData, setOnboardingData] = useRecoilState(
    GlobalAtoms.OnboardingDataAtom
  );

  const createAPIKey = async () => {
    let currentWorkspaceId = currenWorkspace?.id ?? "";

    if (!currentWorkspaceId) {
      const workspaceResponse = await ApiCalls.createNewWorkspace(
        "My Workspace"
      );
      setCurrentWorkspace(workspaceResponse);
    }

    if (onboardingData.testApiKey.apiKey) {
      return;
    }

    const response = await ApiCalls.createApiKey({
      name: "My API Key",
      scopes: [],
      workspaceId: currentWorkspaceId,
      enabled: true,
      customerId: "self-hosted",
    });
    setOnboardingData((prevData) => ({
      ...prevData,
      testApiKey: {
        apiKey: response.apiKey.key,
      },
    }));
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    let checkCount = 0;
    let totalTime = 0;

    const checkForData = async () => {
      try {
        // Replace this with your actual API call
        const response = await ApiCalls.getAnalyticsData(
          currenWorkspace?.id ?? ""
        );

        if (response.data.length > 0) {
          setSignalReceived(true);
          clearInterval(intervalId);
        } else {
          checkCount++;
          if (checkCount <= 10) {
            totalTime += 1;
          } else if (checkCount <= 70) {
            totalTime += 5;
          } else {
            totalTime += 10;
          }
        }
      } catch (error) {
        console.error("Error checking for data:", error);
      }
    };

    const startInterval = () => {
      checkForData();
      intervalId = setInterval(() => {
        if (document.hidden) return;

        if (checkCount <= 10) {
          checkForData();
        } else if (checkCount <= 70 && totalTime % 5 === 0) {
          checkForData();
        } else if (checkCount > 70 && totalTime % 10 === 0) {
          checkForData();
        }
      }, 1000);
    };

    startInterval();

    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        clearInterval(intervalId);
      } else {
        startInterval();
      }
    });

    return () => {
      clearInterval(intervalId);
      document.removeEventListener("visibilitychange", () => {});
    };
  }, [currenWorkspace?.id]);

  return (
    <div>
      <div className="max-w-3xl mx-auto px-4 py-8">
        <h2 className="text-3xl font-bold mb-4">Test your API</h2>
        <p className="text-sm mb-8">
          Test your API from previous step with this API key in authorization
          header
          <div className="flex items-center gap-2 mt-4">
            {onboardingData.testApiKey.apiKey ? (
              <Snippet size="sm" symbol="">
                {onboardingData.testApiKey.apiKey}
              </Snippet>
            ) : (
              <Button size="sm" color="primary" onClick={createAPIKey}>
                Generate API Key
              </Button>
            )}
          </div>
        </p>
      </div>
      <div className="mx-auto px-4 py-8">
        <div className="flex items-center justify-center">
          <div className=" relative">
            {signalReceived ? (
              <div className="flex items-center justify-center w-60 h-20 relative">
                <div className="absolute w-full h-1 bg-green-500"></div>
                <div className="relative z-10 flex items-center justify-center bg-white rounded-full w-12 h-12 border-4 border-green-500">
                  <svg
                    className="w-6 h-6 text-green-500"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
              </div>
            ) : (
              <svg
                className={`w-60 h-20`}
                viewBox="0 0 200 100"
                preserveAspectRatio="none"
              >
                <path
                  d="M0,50 Q16.67,30 33.33,50 T66.67,50 T100,50 T133.33,50 T166.67,50 T200,50"
                  fill="none"
                  stroke="url(#gradient)"
                  strokeWidth="4"
                >
                  <animate
                    attributeName="d"
                    dur="2s"
                    repeatCount="indefinite"
                    values="
                  M0,50 Q16.67,30 33.33,50 T66.67,50 T100,50 T133.33,50 T166.67,50 T200,50;
                  M0,50 Q16.67,70 33.33,50 T66.67,50 T100,50 T133.33,50 T166.67,50 T200,50;
                  M0,50 Q16.67,30 33.33,50 T66.67,50 T100,50 T133.33,50 T166.67,50 T200,50
                "
                  />
                </path>
                <defs>
                  <linearGradient
                    id="gradient"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop offset="0%" stopColor="#60A5FA" />
                    <stop offset="100%" stopColor="#A78BFA" />
                  </linearGradient>
                </defs>
              </svg>
            )}
          </div>
        </div>
        <p className="text-center mt-6 text-lg font-semibold text-gray-700 dark:text-gray-300 transition-opacity duration-300">
          {signalReceived
            ? "Verified your API key successfully!"
            : "Waiting for your first API Key verification..."}
        </p>
        <p className="text-center mt-2 text-sm text-gray-500 dark:text-gray-300">
          {signalReceived
            ? "You're all set to start using the Oneloop API"
            : "Once your code makes first Oneloop verify call, you'll see a success message here"}
        </p>
      </div>
    </div>
  );
};

export default TestAPI;
