import GlobalAtoms from "@/lib/GlobalStore";
import { useRecoilState } from "recoil";
import { BarList, EventProps, FunnelChart, LineChart } from "@tremor/react";
import { Tracker, type Color } from "@tremor/react";
import { useEffect, useState } from "react";
import { ApiCalls } from "@/lib/ApiCall";
import { dateFns, getDatesForTinyBird, utcToYYYYMMDD } from "@/lib/utils";
import { Card, CardBody, CardHeader } from "@nextui-org/react";
import { RotateCcw } from "lucide-react";

// export function AreaChartUsageExampleWithCustomTooltip(props: {
//   data: { date: Date; [string] }[];
// }) {
//   const customTooltip = (props: CustomTooltipProps) => {
//     const { payload, active } = props;
//     if (!active || !payload) return null;
//     return (
//       <div className="w-56 rounded-tremor-default border border-tremor-border bg-tremor-background dark:bg-tremor-background-emphasis p-2 text-tremor-default shadow-tremor-dropdown">
//         {payload.map((category, idx) => (
//           <div key={idx} className="flex flex-1 space-x-2.5">
//             <div
//               className={`flex w-1 flex-col bg-${category.color}-500 rounded`}
//             />
//             <div className="space-y-1">
//               <p className="text-tremor-content dark:text-slate-300">
//                 {category.dataKey}
//               </p>
//               <p className="font-medium text-tremor-content-emphasis dark:text-white">
//                 {category.value} requests
//               </p>
//               <p className="font-medium text-tremor-content-emphasis dark:text-white">
//                 {category.payload.date}
//               </p>
//             </div>
//           </div>
//         ))}
//       </div>
//     );
//   };
//   return (
//     <>
//       <h3 className="text-lg font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
//         API Usage
//       </h3>
//       <AreaChart
//         className="mt-4 h-72"
//         data={props.data}
//         index="date"
//         categories={["API Key Usage"]}
//         colors={["blue"]}
//         yAxisWidth={48}
//         customTooltip={customTooltip}
//       />
//     </>
//   );
// }

interface Tracker {
  color: Color;
  tooltip: string;
}

const operationalData: Tracker[] = new Array(50).fill(0).map((_, idx) => {
  const isError = idx % 16 === 0;
  return {
    color: isError ? "rose" : "emerald",
    tooltip: isError ? "Downtime" : "Operational",
  };
});

const datahero = [
  { name: "/accounts", value: 456 },
  { name: "/accounts/cards", value: 351 },
  { name: "/cancellations", value: 51 },
  { name: "/profile", value: 20 },
  { name: "/transactions/create", value: 16 },
  { name: "/accounts/cards/block", value: 14 },
  { name: "/transactions", value: 4 },
];

export const BarListHero = () => (
  <>
    <BarList data={datahero} className=" max-w-lg" color="blue" />
  </>
);

const funnelChartData = [
  { name: "1. API Keys Issued", value: 3764 },
  { name: "2. API Keys Activated", value: 1833 },
  {
    name: "3. API Key Limit Reached",
    value: 58,
  },
];

export function FunnelChartEvolutionExample() {
  return (
    <FunnelChart
      evolutionGradient
      gradient={false}
      className="h-60"
      data={funnelChartData}
    />
  );
}

export default function DashboardPage() {
  const analyticsData = useRecoilState(GlobalAtoms.AnalyticsDataAtom)[0];
  const currentWorkspace = useRecoilState(GlobalAtoms.SelectedWorkspaceAtom)[0];
  const setLineChartValue = useState<EventProps>(null)[1];

  useEffect(() => {
    const getData = async () => {
      if (currentWorkspace) {
        await ApiCalls.getAnalyticsData(currentWorkspace.id);
      }
    };

    getData();
  }, [currentWorkspace]);

  const dateHourStrings = getDatesForTinyBird({
    timeGap: 60 * 60 * 1000,
    arrayLength: 50,
  });

  const handleChartRefresh = async () => {
    if (currentWorkspace) {
      await ApiCalls.getAnalyticsData(currentWorkspace.id, true);
    }
  };

  return (
    <div>
      <Card className="p-4 max-w-[1500px]">
        <CardHeader>
          <div className=" flex justify-between items-center w-full">
            <span className="text-lg font-medium">Analytics</span>
            <div
              className="flex items-center gap-2 cursor-pointer py-2 px-4 rounded-md hover:bg-default-200"
              onClick={handleChartRefresh}
            >
              <span className="text-xs font-medium">Update</span>
              <RotateCcw size={14} />
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <LineChart
            className="h-96"
            data={dateHourStrings.map((date) => {
              const utcFormattedDate = utcToYYYYMMDD(date);
              const foundData = analyticsData?.data.find(
                (i) =>
                  i.day === utcFormattedDate && i.hour === date.getUTCHours()
              );

              return {
                date: dateFns.format(date, "yyyy-MM-dd HH':00'"),
                "Total Requests": foundData?.total_entries ?? 0,
                "Valid Requests": foundData?.valid_entries ?? 0,
                "Invalid Requests": foundData?.invalid_entries ?? 0,
                "Rate Limit Exceeded": foundData?.rate_limited_entries ?? 0,
                "Usage Limit Exceeded": foundData?.usage_limited_entries ?? 0,
                "Invalid Scopes": foundData?.invalid_scopes_entries ?? 0,
                "Expired Requests": foundData?.expired_entries ?? 0,
                "Deleted Requests": foundData?.deleted_entries ?? 0,
                "Disabled Requests": foundData?.disabled_entries ?? 0,
              };
            })}
            index="date"
            categories={[
              "Total Requests",
              "Valid Requests",
              "Invalid Requests",
              "Rate Limit Exceeded",
              "Usage Limit Exceeded",
              "Invalid Scopes",
              "Expired Requests",
              "Deleted Requests",
              "Disabled Requests",
            ]}
            colors={[
              "blue",
              "green",
              "red",
              "yellow",
              "pink",
              "cyan",
              "fuchsia",
              "zinc",
              "amber",
            ]}
            valueFormatter={(number: any) =>
              `${Intl.NumberFormat("us").format(number).toString()}`
            }
            onValueChange={(v) => setLineChartValue(v)}
            connectNulls={true}
            yAxisWidth={60}
          />
        </CardBody>
      </Card>

      {/* <Card className="mx-auto mt-20">
        <p className="text-tremor-default flex items-center justify-between">
          <span className="text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium">
            API Status
          </span>
          <span className="text-tremor-content dark:text-dark-tremor-content">
            uptime 99.1%
          </span>
        </p>
        <Tracker data={operationalData} className="mt-2" />
      </Card>
      <div className="mt-12 grid grid-cols-2 gap-8">
        <Card>
          <div className="text-tremor-default text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium pb-5">
            Top Routes
          </div>

          <BarListHero />
        </Card>
        <Card>
          <div className="text-tremor-default text-tremor-content-strong dark:text-dark-tremor-content-strong font-medium pb-5">
            User Activation
          </div>

          <FunnelChartEvolutionExample />
        </Card>
      </div> */}
    </div>
  );
}
