import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import SideNav from "@/components/sidenav/sidenav";
import LoadingPage from "./LoadingPage";
import { useRecoilState } from "recoil";
import GlobalAtoms from "@/lib/GlobalStore";
import { cn } from "@/lib/utils";
import { useClerk } from "@clerk/clerk-react";
import { PandaClient } from "@/lib/PandaClient";
import { ApiCalls } from "@/lib/ApiCall";
import PathConstants from "@/lib/PathConstants";
import posthog from "posthog-js";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isNavCollapsed, setIsNavCollapsed] = useState(
    useRecoilState(GlobalAtoms.NavBarHiddenAtom)[0]
  );

  const { session } = useClerk();
  const [sessionId, setSessionId] = useState("");
  const isNavBarHidden = useRecoilState(GlobalAtoms.NavBarHiddenAtom)[0];
  const showUserOnboarding = !useRecoilState(GlobalAtoms.UserOnboardedAtom)[0];

  const navigate = useNavigate();
  const currentPath = useLocation().pathname;

  useEffect(() => {
    if (showUserOnboarding) {
      navigate(PathConstants.ONBOARDING);
    }
  }, [showUserOnboarding, navigate]);

  useEffect(() => {
    const getToken = async () => {
      if (!session) return;
      setIsLoading(true);
      const token = await session?.getToken({
        template: "jwt",
      });

      PandaClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      await ApiCalls.initialize();

      posthog.identify(
        session.user.id, // Replace 'distinct_id' with your user's unique identifier
        {
          email: session.user.primaryEmailAddress?.emailAddress,
          name: session.user.fullName,
        } // optional: set additional person properties
      );

      setTimeout(() => {
        setIsLoading(false);
      }, 200);
    };

    if (session && sessionId !== session.id) {
      setSessionId(session?.id ?? "");
      getToken();
    }
  }, [session, sessionId]);

  return isLoading ? (
    <LoadingPage />
  ) : (
    <div className="flex h-screen flex-row overflow-hidden bg-nav-background">
      {currentPath !== PathConstants.ONBOARDING && (
        <div
          className={cn(
            "transition-all duration-300",
            isNavCollapsed ? "w-16" : "w-64"
          )}
        >
          <SideNav
            defaultCollapsed={isNavCollapsed}
            onToggleCollapse={(collapsed) => setIsNavCollapsed(collapsed)}
          />
        </div>
      )}

      <div
        className={cn(
          "flex-1 flex-grow md:overflow-y-auto bg-primary-background",
          currentPath === PathConstants.ONBOARDING ? "p-0" : "p-12"
        )}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
