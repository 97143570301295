import React, { useState } from "react";
import CreateOrganization from "./steps/CreateOrganization";
import { Button, Link } from "@nextui-org/react";
import OneloopLogo from "@/ui/OneloopLogo";
import { toast } from "sonner";
import GenerateOneloopAPIKey from "./steps/GenerateOneloopAPIKey";
import { useRecoilState } from "recoil";
import GlobalAtoms from "@/lib/GlobalStore";
import IntegrateApplication from "./steps/IntegrateApplication";
import TestAPI from "./steps/TestAPI";
import { useNavigate } from "react-router-dom";
import PathConstants from "@/lib/PathConstants";
import { useClerk } from "@clerk/clerk-react";

const steps = [
  { title: "Create your organization", component: CreateOrganization },
  {
    title: "Generate Oneloop API key",
    component: GenerateOneloopAPIKey,
  },
  {
    title: "Integrate your application",
    component: IntegrateApplication,
  },
  {
    title: "Test your API",
    component: TestAPI,
  },
];

const OnboardingFlowPage: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const onboardingData = useRecoilState(GlobalAtoms.OnboardingDataAtom)[0];
  const navigate = useNavigate();
  const setUserOnboarded = useRecoilState(GlobalAtoms.UserOnboardedAtom)[1];
  const { signOut } = useClerk();

  const handleNext = () => {
    if (currentStep === 0) {
      const errorMessages = [];
      if (onboardingData.createOrganization.companyName === "") {
        errorMessages.push("Please enter your company name");
      }
      if (onboardingData.createOrganization.companySize === "") {
        errorMessages.push("Please select your company size");
      }
      if (onboardingData.createOrganization.referralSource === "") {
        errorMessages.push("Please select your referral source");
      }
      if (errorMessages.length > 0) {
        errorMessages.forEach((error) => {
          toast.error(error, {
            duration: 2000,
          });
        });
        return;
      }
    } else if (currentStep === 1) {
      if (onboardingData.generateOneloopApiKey.apiKey === "") {
        toast.error("Please generate an Oneloop API key", {
          duration: 2000,
        });
        return;
      }
    }

    if (currentStep < steps.length - 1) {
      setCurrentStep((prev) => prev + 1);
    } else {
      navigate(PathConstants.DASHBOARD);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const CurrentStepComponent = steps[currentStep].component;

  return (
    <div className="flex flex-col min-h-screen font-sans">
      <header className="bg-black text-white text-sm p-4">
        <div className="max-w-7xl mx-auto flex items-center">
          <span className="mr-2">⚡</span>
          <span>
            Welcome to the Oneloop demo! Best way to experience it is by having
            your IDE open and you're ready to code 👨‍💻
          </span>
        </div>
      </header>

      <main className="flex flex-1">
        <div className="max-w-7xl mx-auto w-full flex">
          <section className="w-80 p-8 border-r border-gray-200 dark:border-gray-800">
            <div className="max-w-7xl mx-auto flex justify-between mb-8">
              <Link
                onClick={() => {
                  signOut();
                }}
                underline="hover"
                className="text-tiny cursor-pointer"
              >
                Log Out
              </Link>
              <Link
                onClick={() => {
                  setUserOnboarded(true);
                  navigate(PathConstants.DASHBOARD);
                }}
                underline="hover"
                className="text-tiny cursor-pointer"
              >
                Skip Onboarding
              </Link>
            </div>
            <div className="mb-8">
              <OneloopLogo miniIcon width={40} />
            </div>
            <h2 className="text-xl font-bold mb-2">Welcome to Oneloop</h2>
            <p className="text-gray-600 mb-8 dark:text-gray-400">
              Launch your API with Oneloop.
            </p>
            <ul className="relative space-y-8">
              {steps.map((step, index) => (
                <li
                  key={index}
                  className={`flex items-center text-sm ${
                    index === currentStep
                      ? "text-black dark:text-white font-medium"
                      : index < currentStep
                      ? "text-gray-600 dark:text-gray-400 cursor-pointer hover:underline"
                      : "text-gray-400"
                  }`}
                >
                  <div className="relative">
                    <span
                      className={`w-4 h-4 rounded-full border ${
                        index === currentStep
                          ? "border-black bg-black dark:bg-white dark:border-white shadow-[0_0_0_4px_rgba(0,0,0,0.1)]"
                          : index < currentStep
                          ? "border-black bg-black dark:bg-white dark:border-white"
                          : "border-gray-400 bg-white dark:bg-gray-800 dark:border-gray-600"
                      } flex items-center justify-center`}
                    >
                      {index < currentStep && (
                        <svg
                          className="w-2 h-2 text-white dark:text-black"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                    </span>
                    {index < steps.length - 1 && (
                      <div
                        className={`absolute top-4 left-[.45rem] w-px h-10 ${
                          index < currentStep
                            ? "bg-black dark:bg-white"
                            : "bg-transparent"
                        }`}
                      ></div>
                    )}
                  </div>
                  <span
                    className="ml-4"
                    onClick={() => {
                      index < currentStep && setCurrentStep(index);
                    }}
                  >
                    {step.title}
                  </span>
                </li>
              ))}
            </ul>
          </section>
          <section className="flex-1 max-w-3xl p-8 pl-16 pt-16">
            <CurrentStepComponent />
            <div className="flex gap-6 mt-8">
              {currentStep > 0 && currentStep < steps.length - 1 && (
                <Button onClick={handleBack} color="secondary" size="md">
                  Back
                </Button>
              )}
              <Button onClick={handleNext} color="primary" size="md">
                {currentStep === steps.length - 1 ? "Go to dashboard" : "Next"}
              </Button>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};

export default OnboardingFlowPage;
