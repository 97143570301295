import GlobalAtoms from "@/lib/GlobalStore";
import { useRecoilState } from "recoil";
import SettingsApiKeyManager from "../Settings/SettingsApiKeyManager";

const SDKKeysManagerPage = () => {
  const currentTheme = useRecoilState(GlobalAtoms.DashboardThemeAtom)[0];

  return (
    <div>
      <SettingsApiKeyManager
        title="Oneloop SDK keys"
        description="Create your own SDK keys to use with the Oneloop SDK."
        accentColor={currentTheme === "dark" ? "#32C885" : "#ADFF85"}
        textColor={currentTheme === "dark" ? "#ffffff" : "#000000"}
        darkMode={currentTheme === "dark"}
      />
    </div>
  );
};

export default SDKKeysManagerPage;
