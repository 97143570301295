import { ResizablePanel, ResizablePanelGroup } from "@/components/ui/resizable";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";
import { useState } from "react";
import { Nav } from "@/components/sidenav/nav";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
} from "@nextui-org/react";
import {
  Check,
  ChevronDown,
  Home,
  KeyRound,
  LogOut,
  MoonIcon,
  PlusIcon,
  Settings,
  SlidersHorizontal,
  SunIcon,
  PanelLeftCloseIcon,
  PanelLeftOpenIcon,
  Rocket,
  AppWindowMacIcon,
} from "lucide-react";
import OneloopLogo from "@/ui/OneloopLogo";
import PathConstants from "@/lib/PathConstants";
import { Switch } from "@nextui-org/react";
import { useRecoilState } from "recoil";
import GlobalAtoms from "@/lib/GlobalStore";
import { ApiCalls } from "@/lib/ApiCall";
import { UserButton } from "@clerk/clerk-react";

// Add this line near the top of your component
const defaultLayout = [265, 440, 655];

export default function SideNav({
  defaultCollapsed = false,
  onToggleCollapse,
}: {
  defaultCollapsed?: boolean;
  onToggleCollapse: (isCollapsed: boolean) => void;
}) {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);
  const [selectedTheme, setSelectedTheme] = useRecoilState(
    GlobalAtoms.DashboardThemeAtom
  );
  const allWorkspaces = useRecoilState(GlobalAtoms.WorkspacesAtom)[0];
  const [selectedWorkspace, setSelectedWorkspace] = useRecoilState(
    GlobalAtoms.SelectedWorkspaceAtom
  );
  const handleThemeChange = (value: boolean) => {
    setSelectedTheme(value ? "dark" : "light");
  };
  const [newWorkspaceInputValue, setNewWorkspaceInputValue] = useState("");
  const [showCreateWorkspaceModal, setShowCreateWorkspaceModal] =
    useState(false);

  const handleSavingNewWorkspace = async () => {
    await ApiCalls.createNewWorkspace(newWorkspaceInputValue);
  };

  const isDarkTheme = selectedTheme === "dark";

  const toggleCollapse = () => {
    const newCollapsedState = !isCollapsed;
    setIsCollapsed(newCollapsedState);
    onToggleCollapse(newCollapsedState);
  };

  return (
    <div className="h-full flex flex-col bg-nav-background">
      <div className="">
        <div className="flex items-center justify-end p-4">
          <span
            className="text-sm font-medium cursor-pointer"
            onClick={toggleCollapse}
          >
            {isCollapsed ? (
              <PanelLeftOpenIcon size={16} />
            ) : (
              <PanelLeftCloseIcon size={16} />
            )}
          </span>
        </div>
      </div>

      <ResizablePanelGroup
        direction="horizontal"
        onLayout={(sizes: number[]) => {
          document.cookie = `react-resizable-panels:layout=${JSON.stringify(
            sizes
          )}`;
        }}
        className="h-full items-stretch bg-transparent px-2"
      >
        <ResizablePanel
          defaultSize={defaultLayout[0]}
          collapsedSize={isCollapsed ? 50 : 265}
          collapsible={true}
          minSize={15}
          maxSize={400}
          onCollapse={() => {
            setIsCollapsed((isCollapsed) => !isCollapsed);
            document.cookie = `react-resizable-panels:collapsed=${JSON.stringify(
              isCollapsed
            )}`;
          }}
          className={cn(
            isCollapsed &&
              "h-full min-w-[50px] transition-all duration-300 ease-in-out"
          )}
        >
          <div className="flex min-h-[100%] flex-col pt-4">
            <Modal
              isOpen={showCreateWorkspaceModal}
              onOpenChange={(isOpen) => {
                setNewWorkspaceInputValue("");
                setShowCreateWorkspaceModal(isOpen);
              }}
              size="lg"
              className={cn(isDarkTheme && "dark text-white")}
              backdrop="blur"
            >
              <ModalContent className=" max-h-full overflow-scroll">
                {(onClose) => (
                  <>
                    <ModalHeader className="flex flex-col gap-1">
                      Create a new workspace
                    </ModalHeader>{" "}
                    <ModalBody>
                      <span className="text-sm font-medium">
                        Workspace name
                      </span>
                      <Input
                        size="sm"
                        className="w-full rounded-sm"
                        value={newWorkspaceInputValue}
                        onValueChange={(e) => {
                          setNewWorkspaceInputValue(e);
                        }}
                        placeholder="Enter workspace name"
                      />
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        size="sm"
                        className="rounded-sm bg-none border-1 font-medium"
                        onPress={onClose}
                        color="secondary"
                      >
                        Close
                      </Button>
                      <Button
                        size="sm"
                        className="rounded-sm font-medium"
                        color="primary"
                        onPress={() => {
                          handleSavingNewWorkspace();
                          onClose();
                        }}
                      >
                        Create
                      </Button>
                    </ModalFooter>
                  </>
                )}
              </ModalContent>
            </Modal>
            <div className="mb-6 mx-3">
              <OneloopLogo
                width={isCollapsed ? 40 : 150}
                miniIcon={isCollapsed}
              />
              <div className="pt-4">
                {isCollapsed ? null : (
                  <Dropdown>
                    <DropdownTrigger>
                      <Button
                        variant="flat"
                        size="sm"
                        className="min-w-40 justify-between"
                      >
                        {selectedWorkspace?.name} <ChevronDown size="20" />
                      </Button>
                    </DropdownTrigger>
                    <DropdownMenu
                      aria-label="Static Actions"
                      onAction={(key) => {
                        if (key === "__new_item__") {
                          setShowCreateWorkspaceModal(true);
                        } else {
                          setSelectedWorkspace(
                            allWorkspaces.find((w) => w.id === key) ??
                              selectedWorkspace
                          );
                        }
                      }}
                      items={allWorkspaces
                        .map((w) => ({
                          id: w.id,
                          name: w.name,
                        }))
                        .concat([
                          { id: "__new_item__", name: "Create new workspace" },
                        ])}
                    >
                      {(item) => (
                        <DropdownItem key={item.id}>
                          {item.id === "__new_item__" ? (
                            <div className="flex items-center gap-2">
                              <PlusIcon size={14} /> Create new project
                            </div>
                          ) : (
                            <div className="flex items-center gap-2">
                              <Check
                                color={
                                  item.id === selectedWorkspace?.id
                                    ? "black"
                                    : "transparent"
                                }
                                size={14}
                              />{" "}
                              {item.name}
                            </div>
                          )}
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </Dropdown>
                )}
              </div>
            </div>
            <Nav
              isCollapsed={isCollapsed}
              links={[
                // {
                //   title: "Quick Start",
                //   icon: Rocket,
                //   url: PathConstants.QUICK_START,
                // },
                {
                  title: "Dashboard",
                  icon: Home,
                  url: PathConstants.DASHBOARD,
                },
                {
                  title: "Workspace Settings",
                  icon: SlidersHorizontal,
                  url: PathConstants.WORKSPACE_SETTINGS,
                },
                {
                  title: "Customer API Keys",
                  icon: AppWindowMacIcon,
                  url: PathConstants.API_KEYS,
                },
                {
                  title: "Oneloop SDK Keys",
                  icon: KeyRound,
                  url: PathConstants.SDK_KEYS,
                },
              ]}
            />
            <div className="flex-1" />
            <div
              className={`pb-3 mx-3 flex ${
                isCollapsed ? "flex-col-reverse" : "justify-between"
              } items-center`}
            >
              {isCollapsed && (
                <Switch
                  defaultSelected={selectedTheme === "dark"}
                  size="sm"
                  color="default"
                  startContent={<SunIcon size={16} />}
                  endContent={<MoonIcon size={16} />}
                  onValueChange={handleThemeChange}
                  className="mt-4"
                />
              )}
              <UserButton />
              {!isCollapsed && (
                <Switch
                  defaultSelected={selectedTheme === "dark"}
                  size="md"
                  color="default"
                  startContent={<SunIcon />}
                  endContent={<MoonIcon />}
                  onValueChange={handleThemeChange}
                />
              )}
            </div>
            <Separator />

            <Nav
              isCollapsed={isCollapsed}
              links={[
                {
                  title: "Settings",
                  icon: Settings,
                  url: PathConstants.SETTINGS,
                },
                {
                  title: "Logout",
                  icon: LogOut,
                  url: "",
                },
              ]}
            />
          </div>
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  );
}
