import React, { useEffect, useState } from "react";
import { Input, Select, SelectItem } from "@nextui-org/react";
import GlobalAtoms, { OnboardingDataType } from "@/lib/GlobalStore";
import { useRecoilState } from "recoil";

const CreateOrganization: React.FC = () => {
  const [onboardingData, setOnboardingData] = useRecoilState(
    GlobalAtoms.OnboardingDataAtom
  );

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setOnboardingData((prevData) => ({
      ...prevData,
      createOrganization: {
        ...prevData.createOrganization,
        [e.target.name]: e.target.value,
      },
    }));
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-8">Create your organization</h2>
      <div>
        <div className="text-lg font-medium mb-2">
          What is your company name?
        </div>
        <Input
          id="companyName"
          name="companyName"
          value={onboardingData.createOrganization.companyName}
          onChange={handleInputChange}
          placeholder="My Organization"
          fullWidth
        />
        <div className="text-lg font-medium mb-2 mt-8">
          How large is your company?
        </div>
        <Select
          id="companySize"
          name="companySize"
          defaultSelectedKeys={[onboardingData.createOrganization.companySize]}
          onChange={handleInputChange}
          placeholder="Select company size"
          fullWidth
        >
          <SelectItem key="1-10" value="1-10">
            1-10 employees
          </SelectItem>
          <SelectItem key="11-50" value="11-50">
            11-50 employees
          </SelectItem>
          <SelectItem key="51-200" value="51-200">
            51-200 employees
          </SelectItem>
          <SelectItem key="201+" value="201+">
            201+ employees
          </SelectItem>
        </Select>
        <div className="text-lg font-medium mb-2 mt-8">
          How did you hear about us?
        </div>
        <Select
          id="referralSource"
          name="referralSource"
          defaultSelectedKeys={[
            onboardingData.createOrganization.referralSource,
          ]}
          onChange={handleInputChange}
          placeholder="Select referral source"
          fullWidth
        >
          <SelectItem key="search" value="search">
            Search Engine
          </SelectItem>
          <SelectItem key="social" value="social">
            Social Media
          </SelectItem>
          <SelectItem key="friend" value="friend">
            Friend or Colleague
          </SelectItem>
          <SelectItem key="other" value="other">
            Other
          </SelectItem>
        </Select>
      </div>
    </div>
  );
};

export default CreateOrganization;
