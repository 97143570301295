import { LucideIcon } from "lucide-react";

import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Link, useLocation } from "react-router-dom";
import { useClerk } from "@clerk/clerk-react";

interface NavProps {
  isCollapsed: boolean;
  links: {
    title: string;
    label?: string;
    icon: LucideIcon;
    url?: string;
  }[];
}

export function Nav({ links, isCollapsed }: NavProps) {
  const pathname = useLocation().pathname;
  const { signOut } = useClerk();

  const handleLogout = async () => {
    await signOut();
  };

  return (
    <div
      data-collapsed={isCollapsed}
      className="group flex flex-col gap-4 py-4 data-[collapsed=true]:py-2"
    >
      <nav className="grid gap-1 px-2 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2">
        {links.map((link, index) =>
          isCollapsed ? (
            <TooltipProvider key={index}>
              <Tooltip key={index} delayDuration={0}>
                <TooltipTrigger asChild>
                  <Link
                    to={link.url || "#"}
                    onClick={link.title === "Logout" ? handleLogout : undefined}
                    className={cn(
                      buttonVariants({
                        variant: pathname === link.url ? "default" : "ghost",
                        size: "icon",
                      }),
                      "h-9 w-9",
                      pathname === link.url && ""
                    )}
                  >
                    <link.icon className="h-4 w-4" />
                    <span className="sr-only">{link.title}</span>
                  </Link>
                </TooltipTrigger>
                <TooltipContent
                  side="right"
                  className="flex items-center gap-4"
                >
                  {link.title}
                  {link.label && <span className="ml-auto ">{link.label}</span>}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ) : (
            <Link
              key={index}
              to={link.url || "#"}
              onClick={link.title === "Logout" ? handleLogout : undefined}
              className={cn(
                buttonVariants({
                  variant: pathname === link.url ? "default" : "ghost",
                  size: "sm",
                }),
                pathname !== link.url &&
                  "text-nav-foreground hover:bg-zinc-200 dark:hover:bg-zinc-700",
                "justify-start"
              )}
            >
              <link.icon className="mr-2 h-4 w-4" />
              {link.title}
              {link.label && (
                <span className={cn("ml-auto", pathname === link.url && "")}>
                  {link.label}
                </span>
              )}
            </Link>
          )
        )}
      </nav>
    </div>
  );
}
