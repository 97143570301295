import { Tabs, Tab } from "@nextui-org/react";
import UsageSettingsManager from "./UsageSettingsManager";

const SettingsPage = () => {
  return (
    <div>
      <Tabs
        variant="underlined"
        aria-label="Tabs variants"
        classNames={{
          tab: "text-lg font-medium",
          panel: "pt-20 pl-5",
        }}
      >
        <Tab key="usage" title="Usage">
          <UsageSettingsManager />
        </Tab>
      </Tabs>
    </div>
  );
};

export default SettingsPage;
