import GlobalAtoms from "@/lib/GlobalStore";
import { useRecoilState } from "recoil";
import ApiKeyManager from "@/components/api-key-manager/ApiKeyManager";

const ApiKeysManagerPage = () => {
  const currentTheme = useRecoilState(GlobalAtoms.DashboardThemeAtom)[0];

  return (
    <div>
      <ApiKeyManager
        title="Customer keys"
        description="Create keys for your customers directly from the dashboard. Please make sure to keep your keys safe while communicating with your customers."
        accentColor={currentTheme === "dark" ? "#32C885" : "#ADFF85"}
        textColor={currentTheme === "dark" ? "#ffffff" : "#000000"}
        darkMode={currentTheme === "dark"}
      />
    </div>
  );
};

export default ApiKeysManagerPage;
