const PathConstants = {
  DASHBOARD: "/",
  ONBOARDING: "/onboarding",
  QUICK_START: "/quick-start",
  EXPLORER: "/explorer",
  METRICS: "/metrics",
  WORKSPACE_SETTINGS: "/workspace-settings",
  API_KEYS: "/api-keys-manager",
  SDK_KEYS: "/sdk-keys-manager",
  SETTINGS: "/settings",
  SETTINGS_REDIRECT: "/settings/*",
  SIGN_IN: "/sign-in",
  SIGN_UP: "/sign-up",
};

export default PathConstants;
