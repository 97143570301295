import OneloopLogo from "@/ui/OneloopLogo";

const LoadingPage = () => {
  return (
    <div className=" flex flex-col h-screen justify-center items-center animate-pulse">
      <OneloopLogo width={400} />
      <div>Loading</div>
    </div>
  );
};

export default LoadingPage;
