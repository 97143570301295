import axios from "axios";

const baseURL = process.env.REACT_APP_ONELOOP_IS_PRODUCTION
  ? "https://prod.oneloop.ai"
  : "https://dev.oneloop.ai";

export const PandaClient = axios.create({
  baseURL,
  timeout: 10000,
});

PandaClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && [301, 302].includes(error.response.status)) {
      const redirectUrl = error.response.data;

      return Promise.resolve({ data: { redirectUrl } });
    }
    return Promise.reject(error);
  }
);
