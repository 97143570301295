import { Image, Spinner, Tooltip } from "@nextui-org/react";
import React, { useMemo, useState, Suspense, useRef, useEffect } from "react";
import { MDXProvider } from "@mdx-js/react";
import { evaluate } from "@mdx-js/mdx";
import * as runtime from "react/jsx-runtime";
import type { EvaluateOptions } from "@mdx-js/mdx";
import { CodeBlock, vscDarkPlus, PrismLangauge } from "@react-email/code-block";
import { Copy } from "lucide-react";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";

export type MultiLanguageCodeBlockProps = {
  codeBlocks: {
    language: PrismLangauge;
    code: string;
    name: string;
    highlightedCodeLines?: number[];
    highlightedCodeLineColor?: string;
  }[];
  fileName: string;
};

export const MultiLanguageCodeBlock = ({
  codeBlocks,
  fileName,
}: MultiLanguageCodeBlockProps) => {
  const [selectedTab, setSelectedTab] = useState(codeBlocks[0].name);
  const selectedCodeBlock = codeBlocks.find(
    (block) => block.name === selectedTab
  );
  const code = selectedCodeBlock?.code ?? "";
  const codeBlockId = "custom-multi-line-code-block";

  useEffect(() => {
    const codeBlockSection = document.getElementById(codeBlockId);
    if (codeBlockSection) {
      const pTags = codeBlockSection.getElementsByTagName("p");
      Array.from(pTags).forEach((p, codeLine) => {
        if (selectedCodeBlock?.highlightedCodeLines?.includes(codeLine + 1)) {
          p.style.backgroundColor =
            selectedCodeBlock?.highlightedCodeLineColor ?? "#005400";
        }
      });
    }
  }, [selectedCodeBlock]);

  return (
    <div className="mb-4 bg-[#1E1E1E] w-full rounded-lg">
      <div>
        <div className="flex mb-2">
          {codeBlocks.length > 1 &&
            codeBlocks.map((block) => (
              <button
                key={block.name}
                className={`mr-2 px-3 pt-1 pb-2 rounded-t-lg text-xs font-medium ${
                  selectedCodeBlock?.name === block.name
                    ? "border-b-1 text-green-500"
                    : "text-gray-400"
                }`}
                onClick={() => setSelectedTab(block.name)}
              >
                {block.name}
              </button>
            ))}
        </div>
        <div className=" py-1 px-4 flex justify-between items-center">
          <span className="text-sm font-medium font-mono text-white">
            {fileName}
          </span>
          <Tooltip content="Copy code" size="sm">
            <Copy
              size={16}
              className="text-gray-400 hover:text-white cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(code);
                toast.success("Code copied to clipboard", {
                  duration: 2000,
                });
              }}
            />
          </Tooltip>
        </div>
      </div>

      <div id={codeBlockId}>
        <CodeBlock
          code={code}
          style={{
            borderRadius: "0 8px 8px 8px",
            padding: 16,
            paddingTop: 4,
            maxHeight: "400px",
          }}
          lineNumbers
          theme={vscDarkPlus}
          language={selectedCodeBlock?.language || "bash"}
        />
      </div>
    </div>
  );
};

const frameworkDocs: Record<
  string,
  {
    stepTextMdx: string;
    stepCodeMdx: {
      language: PrismLangauge;
      code: string;
      name: string;
    }[];
    fileName: string;
  }[]
> = {
  "Next.js": [
    {
      stepTextMdx:
        "## 1. Install the Oneloop NextJS package\n\nThe package to use with Oneloop and NextJS.",
      stepCodeMdx: [
        {
          language: "bash",
          code: "npm i @oneloop-hq/nextjs@latest",
          name: "npm",
        },
      ],
      fileName: "terminal",
    },
    {
      stepTextMdx: `## 2. Add Oneloop auth to your NextJS routes

Adding Oneloop auth to your NextJS routes is as simple as adding the \`useOneloop\` higher order function to your route handler.

This function takes in two arguments: the route handler and an object with the \`sdkKey\` property set to your Oneloop SDK key. 

Retrieve your SDK key anytime from the [SDK Keys page](/sdk-keys-manager).
`,
      stepCodeMdx: [
        {
          language: "tsx",
          code: `import { useOneloop } from '@Oneloop/nextjs';

const handler = useOneloop(async (req, res) => {
  res.status(200).json({ message: 'Hello World' });
}, { sdkKey: 'your-sdk-key' });

export default handler;
`,
          name: "Add Oneloop auth to your NextJS routes",
        },
      ],
      fileName: "route.ts",
    },
    {
      stepTextMdx: `
        ## 3. Test your API

        You can test your API by calling your endpoint with your API key in the header. Or use our UI below to test your API.

        Generate a test API key from the [API keys page](/api-keys-manager).

        <div className="flex gap-10 items-center">
          <input type="text" placeholder="Enter your API key" />
          <input type="text" placeholder="Enter your API endpoint" />
          <button className="bg-blue-500 text-white px-4 py-2 rounded-md">Test API</button>
        </div>
        `,
      stepCodeMdx: [
        {
          language: "bash",
          code: `curl -X GET \\
"https://your-api-endpoint" \\
-H "Authorization: Bearer YOUR_API_KEY"
          `,
          name: "Add OneloopProvider",
        },
      ],
      fileName: "terminal",
    },
  ],
  Express: [
    {
      stepTextMdx: "## 1. Install the Oneloop Express package",
      stepCodeMdx: [
        {
          language: "bash",
          code: "npm i @oneloop-hq/express@latest",
          name: "npm",
        },
      ],
      fileName: "terminal",
    },
    {
      stepTextMdx: `## 2. Add Oneloop auth to your Express routes

Let’s first add the middleware that will be used to verify the API key. This will save you from having to write the same code in every route.
`,
      stepCodeMdx: [
        {
          language: "tsx",
          code: `import { useOneloop } from '@Oneloop/express';

const handler = useOneloop(async (req, res) => {
  res.status(200).json({ message: 'Hello World' });
}, { sdkKey: 'your-sdk-key' });

export default handler;
`,
          name: "Add Oneloop auth to your Express routes",
        },
      ],
      fileName: "route.ts",
    },
  ],
};

const ListItem = (props: any) => {
  const ref = useRef<HTMLLIElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <li
      ref={ref}
      {...props}
      className={`mb-6 relative ${
        isVisible ? "opacity-100" : "opacity-50"
      } transition-opacity duration-300`}
    >
      <div
        className={`absolute -left-8 top-1.5 w-4 h-4 rounded-full border-2 border-gray-400 bg-white ${
          isVisible ? "bg-blue-500 border-blue-500" : ""
        }`}
      ></div>
      <div className="pl-2">{props.children}</div>
    </li>
  );
};

const LinkItem = (props: any) => {
  const navigate = useNavigate();
  return (
    <span
      onClick={() => navigate(props.href)}
      className="text-blue-500 hover:text-blue-600 cursor-pointer"
    >
      {props.children}
    </span>
  );
};

const InstructionsPage: React.FC = () => {
  const [selectedFramework, setSelectedFramework] = useState("Next.js");

  const frameworks = [
    { name: "Next.js", logo: "/logo-icons/google-icon.svg" },
    { name: "Express", logo: "/logo-icons/google-icon.svg" },
    // ... other frameworks ...
  ];

  const mdxComponents = {
    h1: (props: any) => (
      <h1 {...props} className="text-xl font-semibold mb-5" />
    ),
    h2: (props: any) => <h2 {...props} className="text-lg font-medium mb-4" />,
    p: (props: any) => <p {...props} className="text-sm pb-4 font-sans" />,
    ul: (props: any) => <ul {...props} className="list-disc pl-6 mb-4" />,
    ol: (props: any) => (
      <div className="relative pl-8 mb-4">
        <div className="absolute left-3 top-0 bottom-0 w-0.5 bg-gray-200"></div>
        <ol {...props} className="list-none m-0 p-0" />
      </div>
    ),
    li: ListItem,
    code: (props: any) => (
      <code
        {...props}
        className="bg-gray-100 rounded px-1 py-0.5 font-mono text-xs"
      />
    ),
    pre: (props: any) => {
      return (
        <pre
          {...props}
          className="bg-gray-100 rounded px-1 py-0.5 font-mono text-xs"
        />
      );
    },
    a: LinkItem,
  };

  const MDXContent = useMemo(() => {
    const LazyMDXContent = React.lazy(async () => {
      const Content = () => {
        const [renderedSteps, setRenderedSteps] = useState<React.ReactNode[]>(
          []
        );

        useEffect(() => {
          const renderSteps = async () => {
            const steps = await Promise.all(
              frameworkDocs[selectedFramework].map(async (step, index) => {
                const textContent = await evaluate(step.stepTextMdx, {
                  ...(runtime as unknown as EvaluateOptions),
                  useMDXComponents: () => mdxComponents,
                });

                return (
                  <div className="flex relative gap-10 mb-20" key={index}>
                    <div className="flex-1">
                      <MDXProvider components={mdxComponents}>
                        {textContent.default({ components: mdxComponents })}
                      </MDXProvider>
                    </div>
                    {step.stepCodeMdx.length > 0 && (
                      <div className="w-[400px] sticky top-0 self-start ml-4">
                        <MultiLanguageCodeBlock
                          codeBlocks={step.stepCodeMdx}
                          fileName={step.fileName}
                        />
                      </div>
                    )}
                  </div>
                );
              })
            );
            setRenderedSteps(steps);
          };
          renderSteps();
        }, [selectedFramework]);

        return <>{renderedSteps}</>;
      };
      return { default: Content };
    });

    return (
      <Suspense fallback={<div>Loading...</div>}>
        <LazyMDXContent />
      </Suspense>
    );
  }, [selectedFramework]);

  return (
    <div className="container mx-auto px-4 py-8 max-w-5xl">
      <div className="flex items-center gap-6 mb-8 font-bold">
        <Spinner
          classNames={{
            circle1: "border-b-blue-500",
            circle2: "border-b-blue-500",
          }}
        />
        <span>Watching for verifications...</span>
      </div>

      <div className="mb-8 max-w-lg">
        <h1 className="text-2xl font-semibold mb-4">
          You're just a few steps away from launching your APIs 🎉
        </h1>
        <p className="text-md text-gray-500">
          Choose your framework below to get started. Zero to authenticated in
          less than 5 minutes.
        </p>
      </div>

      <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-7 gap-4 mb-8">
        {frameworks.map((framework) => (
          <div
            key={framework.name}
            className={`flex flex-col items-center cursor-pointer p-4 rounded-lg ${
              selectedFramework === framework.name ? "bg-zinc-100" : ""
            }`}
            onClick={() => setSelectedFramework(framework.name)}
          >
            <Image
              src={framework.logo}
              alt={framework.name}
              width={30}
              height={30}
            />
            <span className="mt-2 text-sm font-semibold text-center">
              {framework.name}
            </span>
          </div>
        ))}
      </div>

      <div className="prose prose-lg max-w-none p-4">
        <MDXProvider components={mdxComponents}>{MDXContent}</MDXProvider>
      </div>
    </div>
  );
};

export default InstructionsPage;
