import { useState } from "react";
import { SignIn, SignUp, useSignUp } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import PathConstants from "@/lib/PathConstants";

const SignInPage = ({ signUpAttempt = false }: { signUpAttempt: boolean }) => {
  const { isLoaded, signUp, setActive } = useSignUp();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isLoaded) return;

    try {
      const result = await signUp.create({
        firstName,
        lastName,
        emailAddress: email,
      });

      if (result.status === "complete") {
        await setActive({ session: result.createdSessionId });
        navigate(PathConstants.DASHBOARD);
      } else {
        // Handle incomplete signup
        console.log(result);
      }
    } catch (err: any) {
      setError(err.message || "An error occurred during sign up");
    }
  };

  return (
    <div className="flex lg:flex-row flex-col-reverse min-h-screen bg-white">
      {/* Left side: Features */}
      <div className=" lg:flex flex-col items-center justify-center w-full lg:w-1/2 lg:bg-black text-white p-12">
        <div className="lg:w-full max-w-xl text-center lg:text-left bg-black mx-auto rounded-lg p-12">
          <h2 className="text-4xl font-bold mb-10">
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-500 to-blue-300">
              Oneloop
            </span>{" "}
            is the easiest way to add authentication to your API
          </h2>

          <div className="space-y-10">
            <FeatureItem
              title="Add authentication in minutes"
              description="With our SDKs in every major framework and language, integrate Oneloop in minutes and start launching your API with confidence."
              className="text-transparent bg-clip-text bg-gradient-to-r from-yellow-500 to-blue-300"
            />
            <FeatureItem
              title="Go beyond authentication"
              description="Add features like rate limiting, auto expiration, usage tracking, and more to your API from Day 1."
              className="text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-cyan-300"
            />
            <FeatureItem
              title="Start building, free"
              description="Oneloop is free to start with, forever. No credit card required. Get started today and never think about auth again."
              className="text-transparent bg-clip-text bg-gradient-to-r from-green-500 to-blue-300"
            />
          </div>

          <Testimonial />
        </div>
      </div>

      {/* Right side: Sign-up form */}
      <div className="w-full lg:w-1/2 flex items-center justify-center p-12 ">
        <style>
          {`
            .cl-internal-b3fm6y {
              color: transparent !important;
              background-color: transparent !important;
              display: none !important;
            }
          `}
        </style>
        {signUpAttempt ? (
          <SignUp
            signInUrl={PathConstants.SIGN_IN}
            appearance={{
              elements: {
                formButtonPrimary: "bg-black text-white",
              },
            }}
          />
        ) : (
          <SignIn
            signUpUrl={PathConstants.SIGN_UP}
            appearance={{
              elements: {
                formButtonPrimary: "bg-black text-white",
              },
            }}
          />
        )}
        {/* <div className="w-full max-w-md ">
          <h1 className="text-3xl font-bold mb-6">Oneloop</h1>
          <h2 className="text-xl mb-6">Create your account</h2>

          <button className="w-full mb-3 p-2 border rounded-md flex items-center justify-center">
            <GithubIcon className="mr-2" /> Continue with Github
          </button>
          <button className="w-full mb-6 p-2 border rounded-md flex items-center justify-center">
            <GoogleIcon className="mr-2" /> Continue with Google
          </button>

          <div className="text-center text-gray-500 mb-6">Or</div>

          <form className="space-y-4" onSubmit={handleSubmit}>
            {error && <div className="text-red-500 text-sm">{error}</div>}
            <input
              type="text"
              placeholder="First name"
              className="w-full p-2 border rounded-md"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Last name"
              className="w-full p-2 border rounded-md"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
            <input
              type="email"
              placeholder="Email"
              className="w-full p-2 border rounded-md"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button
              type="submit"
              className="w-full p-2 bg-black text-white rounded-md"
              disabled={!isLoaded}
            >
              Create your account
            </button>
          </form>

          <div className="mt-6 text-center text-sm">
            <a href="/sign-in" className="text-blue-600 hover:underline">
              Already have an account? Sign in
            </a>
          </div>

          <div className="mt-6 text-center text-xs text-gray-500">
            By continuing, you agree to our policies
            <div className="mt-1">
              <a href="/terms" className="underline">
                Terms of use
              </a>
              {" · "}
              <a href="/privacy" className="underline">
                Privacy Policy
              </a>
            </div>
          </div>
          <span>The current sign-up attempt status is {signUp?.status}.</span>
        </div> */}
      </div>
    </div>
  );
};

const FeatureItem = ({
  title,
  description,
  className,
}: {
  title: string;
  description: string;
  className?: string;
}) => (
  <div>
    <h3 className={`font-semibold text-lg mb-3 ${className}`}>{title}</h3>
    <p className="text-sm text-gray-300">{description}</p>
  </div>
);

const Testimonial = () => (
  <div className="mt-12 flex items-center bg-zinc-900 rounded-lg p-6 shadow-lg">
    <div>
      <p className="font-medium text-md text-white mb-2 italic">
        "I can't believe how easy it was to set up Oneloop. It's a game-changer
        for companies building their APIs."
      </p>
      <div className="flex items-center max-lg:justify-center text-gray-300">
        <p className="text-sm font-medium">Garrett Webster</p>
        <span className="mx-2 ">|</span>
        <p className="text-sm">SignWise</p>
      </div>
    </div>
  </div>
);

const GithubIcon = ({ className }: { className: string }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    {/* Add Github icon SVG path here */}
  </svg>
);

const GoogleIcon = ({ className }: { className: string }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    {/* Add Google icon SVG path here */}
  </svg>
);

export default SignInPage;
