import {
  WorkspaceType,
  WorkspaceScopeType,
  ApiKeyType,
  SdkKeyType,
  UserType,
  TeamType,
} from "@/types/schema";
import { atom } from "recoil";

const isSystemThemeDarkMode = window.matchMedia("(prefers-color-scheme: dark)");

const UserAtom = atom<UserType | null>({
  key: "UserAtom",
  default: null,
});

const TeamAtom = atom<TeamType | null>({
  key: "TeamAtom",
  default: null,
});

const UserOnboardedAtom = atom<boolean>({
  key: "UserOnboardedAtom",
  default: true,
});

const NavBarHiddenAtom = atom<boolean>({
  key: "NavBarHiddenAtom",
  default: false,
});

const DashboardThemeAtom = atom<"light" | "dark">({
  key: "DashboardThemeAtom",
  default: isSystemThemeDarkMode.matches ? "dark" : "light",
});

const WorkspacesAtom = atom<WorkspaceType[]>({
  key: "WorkspacesAtom",
  default: [],
});

const SelectedWorkspaceAtom = atom<WorkspaceType | null>({
  key: "SelectedWorkspaceAtom",
  default: null,
});

const AllScopesAtom = atom<WorkspaceScopeType[]>({
  key: "AllScopesAtom",
  default: [],
});

const AllApiKeysAtom = atom<ApiKeyType[]>({
  key: "AllApiKeysAtom",
  default: [],
});

const AllSDKScopesAtom = atom<WorkspaceScopeType[]>({
  key: "AllSDKScopesAtom",
  default: [],
});

const AllSDKKeysAtom = atom<SdkKeyType[]>({
  key: "AllSDKKeysAtom",
  default: [],
});

const TinyBirdAnalyticsJwtAtom = atom<string | null>({
  key: "TinyBirdJwTinyBirdAnalyticsJwtAtomAtom",
  default: null,
});

const TinyBirdBillingJwtAtom = atom<string | null>({
  key: "TinyBirdBillingJwtAtom",
  default: null,
});

const AnalyticsDataAtom = atom<
  (TinyBirdAnalyticsDataType & { workspaceId: string }) | null
>({
  key: "AnalyticsDataAtom",
  default: null,
});

const UsageDataAtom = atom<TinyBirdBillingDataType>({
  key: "UsageDataAtom",
  default: {
    meta: [],
    data: [],
    rows: 0,
    statistics: {
      elapsed: 0,
      rows_read: 0,
      bytes_read: 0,
    },
  },
});

const OnboardingDataAtom = atom<OnboardingDataType>({
  key: "OnboardingDataAtom",
  default: {
    createOrganization: {
      companyName: "",
      companySize: "",
      referralSource: "",
    },
    generateOneloopApiKey: {
      apiKey: "",
    },
    testApiKey: {
      apiKey: "",
    },
  },
});

const GlobalAtoms = {
  UserAtom,
  TeamAtom,
  UserOnboardedAtom,
  NavBarHiddenAtom,
  DashboardThemeAtom,
  WorkspacesAtom,
  SelectedWorkspaceAtom,
  AllScopesAtom,
  AllApiKeysAtom,
  AllSDKScopesAtom,
  AllSDKKeysAtom,
  AnalyticsDataAtom,
  UsageDataAtom,
  TinyBirdAnalyticsJwtAtom,
  TinyBirdBillingJwtAtom,
  OnboardingDataAtom,
};

type AnalyticsMetaField =
  | { name: "workspaceId"; type: "String" }
  | { name: "day"; type: "Date" }
  | { name: "hour"; type: "UInt8" }
  | { name: "total_entries"; type: "UInt64" }
  | { name: "valid_entries"; type: "UInt64" }
  | { name: "invalid_entries"; type: "UInt64" }
  | { name: "rate_limited_entries"; type: "UInt64" }
  | { name: "usage_limited_entries"; type: "UInt64" }
  | { name: "expired_entries"; type: "UInt64" }
  | { name: "deleted_entries"; type: "UInt64" }
  | { name: "disabled_entries"; type: "UInt64" }
  | { name: "invalid_scopes_entries"; type: "UInt64" };

type AnalyticsDataEntry = {
  workspaceId: string;
  day: string;
  hour: number;
  total_entries: number;
  valid_entries: number;
  invalid_entries: number;
  rate_limited_entries: number;
  usage_limited_entries: number;
  expired_entries: number;
  deleted_entries: number;
  disabled_entries: number;
  invalid_scopes_entries: number;
};

export type TinyBirdAnalyticsDataType = {
  meta: AnalyticsMetaField[];
  data: AnalyticsDataEntry[];
  rows: number;
  statistics: {
    elapsed: number;
    rows_read: number;
    bytes_read: number;
  };
};

type UsageMetaField =
  | { name: "teamId"; type: "String" }
  | { name: "workspaceId"; type: "String" }
  | { name: "day"; type: "Date" }
  | { name: "total_entries"; type: "UInt64" };

type UsageDataEntry = {
  teamId: string;
  workspaceId: string;
  day: string;
  total_entries: number;
};

export type TinyBirdBillingDataType = {
  meta: UsageMetaField[];
  data: UsageDataEntry[];
  rows: number;
  statistics: {
    elapsed: number;
    rows_read: number;
    bytes_read: number;
  };
};

export type OnboardingDataType = {
  createOrganization: {
    companyName: string;
    companySize: string;
    referralSource: string;
  };
  generateOneloopApiKey: {
    apiKey: string;
  };
  testApiKey: {
    apiKey: string;
  };
};

export default GlobalAtoms;
