import { useRecoilState } from "recoil";
import GlobalAtoms from "@/lib/GlobalStore";
import { Button, Snippet } from "@nextui-org/react";
import { ApiCalls } from "@/lib/ApiCall";

const GenerateOneloopAPIKey: React.FC = () => {
  const allSDKKeyScopes = useRecoilState(GlobalAtoms.AllSDKScopesAtom)[0];
  const [onboardingData, setOnboardingData] = useRecoilState(
    GlobalAtoms.OnboardingDataAtom
  );

  const handleGenerateApiKey = async () => {
    const newApiKey = await ApiCalls.createSDKKey({
      name: "My API Key",
      scopes: allSDKKeyScopes.map((scope) => ({
        representation: scope.representation,
        create: true,
        read: true,
        update: true,
        del: true,
      })),
      enabled: true,
    });
    setOnboardingData((prevData) => ({
      ...prevData,
      generateOneloopApiKey: {
        apiKey: newApiKey.apiKey.key,
      },
    }));
  };

  return (
    <div className="">
      <h2 className="text-3xl font-bold mb-4">Generate Oneloop API key</h2>
      <p className="text-sm mb-12">
        Your Oneloop API key is used to authenticate your requests to the
        Oneloop API.
      </p>
      <div className="flex items-center mb-10">
        <Snippet
          size="sm"
          className="min-w-80 mr-12"
          key={onboardingData.generateOneloopApiKey.apiKey}
          symbol=""
        >
          {onboardingData.generateOneloopApiKey.apiKey}
        </Snippet>

        <Button onClick={handleGenerateApiKey} size="sm" color="primary">
          Generate API Key
        </Button>
      </div>
    </div>
  );
};

export default GenerateOneloopAPIKey;
