export default function OneloopLogo({
  width = 100,
  height = 24,
  miniIcon = false,
  isLight = false,
}) {
  return (
    <div>
      <img
        src={`/oneloop-logo${miniIcon ? "-mini" : ""}.svg`}
        alt="Oneloop Logo"
        className={`${isLight ? "invert" : "dark:invert"}`}
        width={width}
        height={height}
      />
    </div>
  );
}
