import "./App.css";
import { BrowserRouter, Navigate, Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import { NextUIProvider } from "@nextui-org/react";
import { useRecoilState } from "recoil";
import PathConstants from "./lib/PathConstants";
import GlobalAtoms from "./lib/GlobalStore";
import { cn } from "@/lib/utils";
import ApiSettingsPage from "./pages/ApiSettings/ApiSettingsPage";
import ApiKeysManagerPage from "./pages/ApiKeysManager/ApiKeysManagerPage";
import { ClerkProvider, SignedIn, SignedOut } from "@clerk/clerk-react";
import SettingsPage from "./pages/Settings/SettingsPage";
import OnboardingPage from "./pages/Onboarding/OnboardingPage";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import { PostHogProvider } from "posthog-js/react";
import InstructionsPage from "./pages/Onboarding/InstructionsPage";
import SDKKeysManagerPage from "./pages/SDKKeysManager/SDKKeysManagerPage";
import OnboardingFlowPage from "./pages/Onboarding/OnboardingFlowPage";
import SignInPage from "./pages/SignInPage";

function App() {
  const selectedTheme = useRecoilState(GlobalAtoms.DashboardThemeAtom)[0];

  return (
    <NextUIProvider>
      <main
        className={cn(
          selectedTheme === "dark" ? "dark" : "light",
          " bg-background text-foreground"
        )}
      >
        <BrowserRouter>
          <PostHogProvider
            apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
            options={{
              api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
            }}
          >
            <ClerkProvider
              publishableKey={
                process.env.REACT_APP_ONELOOP_CLERK_PUBLISHABLE_KEY ?? ""
              }
            >
              <SignedIn>
                <Routes>
                  <Route path={PathConstants.DASHBOARD} Component={Dashboard}>
                    {/* <Route
                      path={PathConstants.QUICK_START}
                      element={<InstructionsPage />}
                    /> */}
                    <Route
                      path={PathConstants.DASHBOARD}
                      element={<DashboardPage />}
                    />
                    <Route
                      path={PathConstants.ONBOARDING}
                      element={<OnboardingFlowPage />}
                    />
                    <Route
                      path={PathConstants.WORKSPACE_SETTINGS}
                      element={<ApiSettingsPage />}
                    />
                    <Route
                      path={PathConstants.API_KEYS}
                      element={<ApiKeysManagerPage />}
                    />
                    <Route
                      path={PathConstants.SETTINGS}
                      element={<SettingsPage />}
                    />

                    <Route
                      path={PathConstants.SDK_KEYS}
                      element={<SDKKeysManagerPage />}
                    />
                    <Route
                      path="*"
                      element={<Navigate to={PathConstants.DASHBOARD} />}
                    />
                  </Route>
                </Routes>
              </SignedIn>
              <SignedOut>
                <Routes>
                  <Route
                    path={PathConstants.SIGN_IN}
                    element={<SignInPage signUpAttempt={false} />}
                  />
                  <Route
                    path={PathConstants.SIGN_UP}
                    element={<SignInPage signUpAttempt={true} />}
                  />
                  <Route
                    path="*"
                    element={<Navigate to={PathConstants.SIGN_IN} />}
                  />
                </Routes>
              </SignedOut>
            </ClerkProvider>
          </PostHogProvider>
        </BrowserRouter>
      </main>
    </NextUIProvider>
  );
}

export default App;
