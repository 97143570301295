import React, { useState } from "react";
import { Button, code, Link } from "@nextui-org/react";
import {
  MultiLanguageCodeBlock,
  MultiLanguageCodeBlockProps,
} from "../InstructionsPage";
import { useRecoilState } from "recoil";
import GlobalAtoms from "@/lib/GlobalStore";
import { BookOpen, Heart } from "lucide-react";

const languages: (MultiLanguageCodeBlockProps & {
  guideLink: string;
  starterKitLink?: string;
})[] = [
  {
    fileName: "index.ts",
    guideLink: "https://docs.oneloop.ai/sdk/nodejs",
    codeBlocks: [
      {
        language: "javascript",
        code: `import { OneloopClient } from "@oneloop-hq/oneloop-ts";

const oneloop = new OneloopClient({token: "your_oneloop_api_key"});

// Make sure to replace "your-generated-api-key" with the actual API key you generate in the next step
const result = await oneloop.verifyApiKey("your-generated-api-key");
if (result.status === "VALID") {
  // Create something
}
`,
        highlightedCodeLines: [1, 3, 6],
        name: "Node",
      },
    ],
  },
  {
    fileName: "main.py",
    guideLink: "https://docs.oneloop.ai/sdk/python",
    codeBlocks: [
      {
        language: "py",
        code: `from oneloop_client.client import Oneloop

oneloop = Oneloop(api_key="your_oneloop_api_key")

# Make sure to replace "your-generated-api-key" with the actual API key you generate in the next step
result = oneloop.verify_api_key(api_key="your-generated-api-key", requested_scopes=[])

if result.status == "VALID":
    # Create something
`,
        highlightedCodeLines: [1, 3, 6],
        name: "Python",
      },
    ],
  },
  {
    fileName: "routes.ts",
    guideLink: "https://docs.oneloop.ai/sdk/starter-kits/express",
    starterKitLink: "https://github.com/oneloop-starter-kits/express",
    codeBlocks: [
      {
        language: "javascript",
        code: `import { OneloopClient } from "@oneloop-hq/oneloop-ts";
import { Request, Response } from "express";

const app = express();
const oneloop = new OneloopClient({ token: "your_oneloop_api_key" });

app.post("/webhook", async (req: Request, res: Response) => {
  const isKeyValid = await oneloop.verifyApiKey({
    key: req.headers.authorization?.replace("Bearer ", "") ?? "",
    requestedScopes: [],
  });

  if (isKeyValid.status === "VALID") {
    // Create something
  }
});
`,
        highlightedCodeLines: [1, 5, 8, 9, 10, 11],

        name: "Express",
      },
    ],
  },
  {
    fileName: "fastapi_app.py",
    guideLink: "https://docs.oneloop.ai/sdk/starter-kits/fastapi",
    starterKitLink: "https://github.com/oneloop-starter-kits/fastAPI",
    codeBlocks: [
      {
        language: "py",
        code: `from fastapi import FastAPI, Request
from oneloop_client.client import Oneloop

app = FastAPI()

oneloop = Oneloop(token="your_oneloop_api_key")

@app.get("/profile")
async def read_root(request: Request):
    is_valid = oneloop.verify_api_key(
      api_key=request.headers.get("Authorization").replace("Bearer ", ""),
      requested_scopes=[]
    )

    if not is_valid.status == "VALID":
        return {"error": "Invalid API key"}
`,
        highlightedCodeLines: [2, 6, 10, 11, 12, 13],
        name: "FastAPI",
      },
    ],
  },
  {
    fileName: "route.ts",
    guideLink: "https://docs.oneloop.ai/sdk/nextjs",
    starterKitLink: "https://github.com/oneloop-starter-kits/nextjs",
    codeBlocks: [
      {
        language: "javascript",
        code: `import { createOneloop } from "@oneloop-hq/nextjs";

const oneloop = createOneloop("your_oneloop_api_key");

export const GET = oneloop.auth(
  async (request: Request) => {
    return new Response("Profile Endpoint Authenticated");
  },
);
`,
        highlightedCodeLines: [3, 5],
        name: "NextJS",
      },
    ],
  },
];

const IntegrateApplication = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);
  const onboardingData = useRecoilState(GlobalAtoms.OnboardingDataAtom)[0];

  return (
    <div className="max-w-md lg:max-w-3xl mx-auto">
      <h1 className="text-3xl font-bold mb-8">Integrate your application</h1>

      <div className="space-y-6">
        <section>
          <h2 className="text-xl font-semibold mb-4">
            Select your language or framework
          </h2>
          <div className="flex flex-wrap gap-4">
            {languages.map((language) => (
              <Button
                key={language.codeBlocks[0].name}
                color={selectedLanguage === language ? "primary" : "secondary"}
                onClick={() => setSelectedLanguage(language)}
              >
                {language.codeBlocks[0].name}
              </Button>
            ))}
          </div>
        </section>

        <section className="pt-2">
          <MultiLanguageCodeBlock
            key={selectedLanguage.codeBlocks[0].name}
            codeBlocks={selectedLanguage.codeBlocks.map((codeBlock) => ({
              ...codeBlock,
              code: codeBlock.code.replace(
                "your_oneloop_api_key",
                `${onboardingData.generateOneloopApiKey.apiKey}`
              ),
            }))}
            fileName={selectedLanguage.fileName}
          />
        </section>
        <section className="mt-8 p-6 bg-black rounded-lg shadow-md text-white dark:bg-slate-400 dark:text-black transition-all duration-300 hover:bg-gradient-to-r hover:from-blue-500 hover:to-purple-600">
          <h3 className="text-md font-semibold mb-2">
            Oneloop <Heart size={16} className="inline-block fill-white mx-1" />{" "}
            {selectedLanguage.codeBlocks[0].name}
          </h3>
          <p className="text-sm text-gray-200 dark:text-gray-800 mb-4">
            Explore our comprehensive product guide for in-depth instructions
            and best practices.
          </p>

          <div className="flex flex-row gap-4">
            <Button
              href={selectedLanguage.guideLink}
              className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200"
              size="sm"
              onClick={() => {
                window.open(selectedLanguage.guideLink, "_blank");
              }}
            >
              <BookOpen size={14} />

              <span>View Guide</span>
            </Button>
            {selectedLanguage.starterKitLink && (
              <Button
                href={selectedLanguage.starterKitLink}
                className="inline-flex items-center px-4 py-2"
                size="sm"
                onClick={() => {
                  window.open(selectedLanguage.starterKitLink, "_blank");
                }}
                color="primary"
              >
                <span>View Starter Kit</span>
              </Button>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default IntegrateApplication;
