import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Snippet,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Textarea,
} from "@nextui-org/react";
import { Plus } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { Switch as Switch2 } from "@/components/ui/switch";
import { useRecoilState } from "recoil";
import GlobalAtoms from "@/lib/GlobalStore";
import { ApiCalls } from "@/lib/ApiCall";
import { CreateWorkspaceScopeRequestType } from "@/types/scope";
import { toast } from "sonner";
import { cn } from "@/lib/utils";

const accessGroups = [
  "Balance Transfers",
  "Test clocks",
  "Charges",
  "Confirmation token",
  "Customers",
  "Customer session",
  "Disputes",
  "Events",
  "Ephemeral keys",
];

const ApiSettingsPage = () => {
  const isDarkTheme =
    useRecoilState(GlobalAtoms.DashboardThemeAtom)[0] === "dark";
  const [expirationAllowed, setExpirationAllowed] = useState(false);
  const [keyPrefixAllowed, setKeyPrefixAllowed] = useState(false);
  const [showAddScopeModal, setShowAddScopeModal] = useState(false);
  const [defaultRateLimitPerSecond, setDefaultRateLimitPerSecond] = useState(0);
  const [newScopeObject, setNewScopeObject] =
    useState<CreateWorkspaceScopeRequestType>({
      name: "",
      representation: "",
      description: "",
    });

  const [expirationSelectedKeys, setExpirationSelectedKeys] = useState(
    new Set(["months"])
  );

  const expirationSelectedValue = useMemo(
    () => Array.from(expirationSelectedKeys).join(", ").replaceAll("_", " "),
    [expirationSelectedKeys]
  );

  const currentWorkspace = useRecoilState(GlobalAtoms.SelectedWorkspaceAtom)[0];

  const [keyPrefixInput, setKeyPrefixInput] = useState(
    currentWorkspace?.defaultKeyPrefix ?? ""
  );
  const [expirationInput, setExpirationInput] = useState(
    currentWorkspace?.defaultExpiration ?? 0
  );

  const allScopes = useRecoilState(GlobalAtoms.AllScopesAtom)[0];

  useEffect(() => {
    const getData = async () => {
      if (!currentWorkspace) return;
      await ApiCalls.getAllScopes(currentWorkspace.id);
    };

    getData();

    if (currentWorkspace?.defaultKeyPrefix) {
      setKeyPrefixAllowed(true);
      setKeyPrefixInput(currentWorkspace.defaultKeyPrefix);
    } else {
      setKeyPrefixAllowed(false);
      setKeyPrefixInput("");
    }

    if (currentWorkspace?.defaultExpiration) {
      setExpirationAllowed(true);

      if (currentWorkspace.defaultExpiration < 60) {
        setExpirationSelectedKeys(new Set(["secs"]));
        setExpirationInput(currentWorkspace.defaultExpiration);
      } else if (currentWorkspace.defaultExpiration < 60 * 60) {
        setExpirationSelectedKeys(new Set(["mins"]));
        setExpirationInput(currentWorkspace.defaultExpiration / 60);
      } else if (currentWorkspace.defaultExpiration < 60 * 60 * 24) {
        setExpirationSelectedKeys(new Set(["hrs"]));
        setExpirationInput(currentWorkspace.defaultExpiration / 60 / 60);
      } else if (currentWorkspace.defaultExpiration < 60 * 60 * 24 * 7) {
        setExpirationSelectedKeys(new Set(["days"]));
        setExpirationInput(currentWorkspace.defaultExpiration / 60 / 60 / 24);
      } else if (currentWorkspace.defaultExpiration < 60 * 60 * 24 * 30) {
        setExpirationSelectedKeys(new Set(["weeks"]));
        setExpirationInput(
          currentWorkspace.defaultExpiration / 60 / 60 / 24 / 7
        );
      } else if (currentWorkspace.defaultExpiration < 60 * 60 * 24 * 365) {
        setExpirationSelectedKeys(new Set(["months"]));
        setExpirationInput(
          currentWorkspace.defaultExpiration / 60 / 60 / 24 / 30
        );
      } else {
        setExpirationSelectedKeys(new Set(["years "]));
        setExpirationInput(
          currentWorkspace.defaultExpiration / 60 / 60 / 24 / 365
        );
      }
    } else {
      setExpirationAllowed(false);
      setExpirationInput(0);
    }

    if (currentWorkspace?.defaultRateLimitPerSecond) {
      setDefaultRateLimitPerSecond(currentWorkspace.defaultRateLimitPerSecond);
    } else {
      setDefaultRateLimitPerSecond(0);
    }
  }, [currentWorkspace]);

  const handleSaveScope = async () => {
    if (!currentWorkspace) return;
    await ApiCalls.createWorkspaceScope(currentWorkspace.id, newScopeObject);
    setNewScopeObject({
      name: "",
      representation: "",
      description: "",
    });
  };

  const handleEditWorkspaceSettingsSave = async () => {
    if (!currentWorkspace) return;

    let expirationInSecs = 0;

    if (expirationAllowed) {
      if (expirationSelectedKeys.has("secs")) {
        expirationInSecs = expirationInput * 1;
      } else if (expirationSelectedKeys.has("mins")) {
        expirationInSecs = expirationInput * 60;
      } else if (expirationSelectedKeys.has("hrs")) {
        expirationInSecs = expirationInput * 60 * 60;
      } else if (expirationSelectedKeys.has("days")) {
        expirationInSecs = expirationInput * 60 * 60 * 24;
      } else if (expirationSelectedKeys.has("weeks")) {
        expirationInSecs = expirationInput * 60 * 60 * 24 * 7;
      } else if (expirationSelectedKeys.has("months")) {
        expirationInSecs = expirationInput * 60 * 60 * 24 * 30;
      } else if (expirationSelectedKeys.has("years")) {
        expirationInSecs = expirationInput * 60 * 60 * 24 * 365;
      }
    }

    await ApiCalls.updateWorkspace(currentWorkspace.id, {
      defaultKeyPrefix: keyPrefixAllowed ? keyPrefixInput : null,
      defaultAllowedIPs: [],
      defaultExpiration: expirationAllowed ? expirationInSecs : null,
      defaultRateLimitPerSecond: defaultRateLimitPerSecond || null,
    });
  };

  return (
    <div>
      <div className="pb-10 flex gap-3 justify-end">
        <Button
          size="sm"
          color="primary"
          onClick={handleEditWorkspaceSettingsSave}
        >
          Save
        </Button>
      </div>
      <div className="flex gap-10 flex-wrap">
        <div className="text-sm font-medium w-full">
          Your workspace ID is:{" "}
          <Snippet size="sm" variant="solid" hideSymbol>
            {currentWorkspace?.id}
          </Snippet>
        </div>
        <div className="w-[700px]">
          <Card className="p-4">
            <CardHeader>
              <div className="flex-1 flex flex-col gap-2">
                <div className=" text-lg font-medium">Manage Scopes</div>
              </div>
            </CardHeader>
            <CardBody>
              <div className="text-sm">
                <Table>
                  <TableHeader>
                    <TableColumn>Scope Name</TableColumn>
                    <TableColumn>Scope ID</TableColumn>
                    <TableColumn>Description</TableColumn>
                  </TableHeader>
                  <TableBody>
                    {allScopes.map((scope) => (
                      <TableRow key={scope.id}>
                        <TableCell className="text-xs">{scope.name}</TableCell>
                        <TableCell className="text-xs">
                          {scope.representation}
                        </TableCell>
                        <TableCell className="text-xs">
                          {scope.description}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                <div className="pt-6 flex gap-2 items-center">
                  <Button
                    size="sm"
                    color="default"
                    onClick={() => {
                      setShowAddScopeModal(true);
                    }}
                  >
                    <Plus size={14} /> Create New Scope
                  </Button>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="w-[400px]">
          <Card className="p-4">
            <CardHeader>
              <div className="flex-1 flex flex-col gap-2">
                <div className=" text-lg font-medium">API Configuration</div>
              </div>
            </CardHeader>
            <CardBody>
              <div className="flex flex-col gap-4 text-sm">
                <div className="flex justify-between items-center ">
                  Expire Keys
                  <div className="flex gap-2">
                    {expirationAllowed && (
                      <>
                        <Input
                          size="sm"
                          className="max-w-20"
                          min={0}
                          type="number"
                          value={expirationInput.toString()}
                          onValueChange={(e) => {
                            setExpirationInput(Number(e));
                          }}
                        />
                        <Dropdown size="sm">
                          <DropdownTrigger>
                            <Button size="sm" variant="bordered">
                              {expirationSelectedValue}
                            </Button>
                          </DropdownTrigger>
                          <DropdownMenu
                            aria-label="Static Actions"
                            disallowEmptySelection
                            selectionMode="single"
                            selectedKeys={expirationSelectedKeys}
                            onSelectionChange={(keys) => {
                              if (keys) {
                                setExpirationSelectedKeys(
                                  new Set([keys.anchorKey ?? "months"])
                                );
                              }
                            }}
                          >
                            <DropdownItem key="secs">Seconds</DropdownItem>
                            <DropdownItem key="mins">Minutes</DropdownItem>
                            <DropdownItem key="hrs">Hours</DropdownItem>
                            <DropdownItem key="days">Days</DropdownItem>
                            <DropdownItem key="weeks">Weeks</DropdownItem>
                            <DropdownItem key="months">Months</DropdownItem>
                            <DropdownItem key="years">Years</DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </>
                    )}
                    <Switch
                      size="sm"
                      color="success"
                      isSelected={expirationAllowed}
                      onValueChange={(e) => {
                        setExpirationAllowed(e);
                      }}
                    />
                  </div>
                </div>
                <div className=" flex justify-between items-center ">
                  Rate Limit
                  <div className="flex gap-2">
                    {defaultRateLimitPerSecond !== 0 && (
                      <Input
                        size="sm"
                        className="max-w-32"
                        value={String(defaultRateLimitPerSecond ?? 10)}
                        onValueChange={(e) => {
                          setDefaultRateLimitPerSecond(Number(e));
                        }}
                        type="number"
                        endContent="req/sec"
                      />
                    )}
                    <Switch
                      size="sm"
                      color="success"
                      isSelected={defaultRateLimitPerSecond ? true : false}
                      onValueChange={(e) => {
                        setDefaultRateLimitPerSecond(e ? 10 : 0);
                      }}
                    />
                  </div>
                </div>

                <div className="flex justify-between items-center">
                  Key Prefix
                  <div className="flex gap-2">
                    {keyPrefixAllowed && (
                      <Input
                        size="sm"
                        className="max-w-20"
                        maxLength={10}
                        value={keyPrefixInput}
                        onValueChange={(e) => {
                          setKeyPrefixInput(e);
                        }}
                        placeholder="sk_"
                      />
                    )}
                    <Switch
                      size="sm"
                      color="success"
                      isSelected={keyPrefixAllowed}
                      onValueChange={(e) => {
                        setKeyPrefixAllowed(e);
                      }}
                    />
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
      <Modal
        isOpen={showAddScopeModal}
        onOpenChange={(isOpen) => {
          setShowAddScopeModal(isOpen);
        }}
        size="lg"
        className={cn(isDarkTheme && "dark text-white")}
      >
        <ModalContent className=" max-h-full overflow-scroll">
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Create a new scope
              </ModalHeader>
              <ModalBody>
                <span className="text-sm font-medium">Scope name</span>
                <Input
                  size="sm"
                  className="w-full rounded-sm"
                  placeholder="e.g. Billing & Invoices"
                  value={newScopeObject.name}
                  onValueChange={(e) => {
                    setNewScopeObject({ ...newScopeObject, name: e });
                  }}
                />
                <span className="text-sm font-medium">Scope ID</span>
                <Input
                  size="sm"
                  className="w-full rounded-sm"
                  placeholder="e.g. billing_invoices"
                  value={newScopeObject.representation}
                  onValueChange={(e) => {
                    setNewScopeObject({ ...newScopeObject, representation: e });
                  }}
                />
                <span className="text-sm font-medium">Scope description</span>
                <Textarea
                  size="sm"
                  className="w-full rounded-sm"
                  placeholder="e.g. Access to billing and invoices"
                  value={newScopeObject.description}
                  onValueChange={(e) => {
                    setNewScopeObject({ ...newScopeObject, description: e });
                  }}
                />
              </ModalBody>
              <ModalFooter>
                <Button
                  size="sm"
                  className="rounded-sm bg-none border-1 font-medium"
                  onPress={onClose}
                  color="secondary"
                >
                  Close
                </Button>
                <Button
                  size="sm"
                  color="primary"
                  isDisabled={
                    !newScopeObject.name ||
                    !newScopeObject.representation ||
                    !newScopeObject.description
                  }
                  className="rounded-sm font-medium"
                  onPress={() => {
                    handleSaveScope();
                    onClose();
                  }}
                >
                  Create
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ApiSettingsPage;
